import React from 'react';

const blackBorderlessLogo = (props) => {
    return (
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            x='0px' 
			y='0px'
            viewBox="0 0 700.000000 900.000000" 
			role='presentation' 
			aria-hidden='true' 
			focusable='false'
            width={props.width ? props.width : '700.000000pt'} 
			height={props.height ? props.height : '900.000000pt'}
			fill={props.fill ? props.fill : null}
			style={props.style ? props.style : {height: '1.75em', width: '1.75em', display: 'inline', fill: 'currentcolor'}}>
            <g transform="translate(0.000000,900.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path fill="#000000" d="M3315 8970 c-242 -15 -499 -59 -750 -130 -183 -52 -398 -149 -545
                -245 -84 -56 -228 -189 -271 -251 -46 -68 -88 -149 -111 -216 -18 -53 -21 -86
                -21 -203 0 -143 1 -150 55 -295 83 -224 372 -538 703 -762 198 -135 321 -206
                570 -333 261 -132 300 -148 326 -135 15 8 19 18 16 33 -54 222 -50 305 20 387
                63 73 155 100 339 100 129 0 259 -17 429 -56 39 -9 86 -19 105 -24 35 -8 93
                -24 200 -55 30 -9 69 -20 85 -25 49 -13 354 -117 445 -150 47 -18 96 -36 110
                -40 63 -21 437 -162 475 -179 58 -26 106 -40 120 -35 23 9 988 1945 979 1967
                -3 10 -27 27 -53 38 -25 11 -55 24 -66 29 -141 62 -563 210 -810 284 -49 14
                -106 32 -125 38 -19 6 -51 15 -70 19 -19 4 -62 15 -95 24 -65 17 -167 42 -230
                56 -22 4 -71 15 -110 24 -109 24 -224 45 -295 55 -36 5 -72 11 -80 14 -41 15
                -414 55 -605 66 -183 10 -563 10 -740 0z m727 -70 c226 -15 442 -40 648 -75
                201 -34 267 -46 300 -54 19 -5 71 -17 115 -27 105 -23 280 -66 380 -94 44 -12
                94 -26 110 -30 30 -7 52 -15 290 -92 106 -34 252 -85 310 -108 11 -4 39 -15
                63 -24 96 -37 129 -49 159 -62 17 -8 35 -14 40 -14 4 0 18 -8 30 -18 l23 -17
                -459 -918 c-253 -506 -462 -921 -465 -924 -4 -5 -97 25 -161 52 -29 12 -69 28
                -157 61 -24 9 -52 20 -63 24 -11 4 -42 16 -70 25 -27 9 -64 23 -82 31 -17 8
                -37 14 -45 14 -8 0 -17 3 -21 6 -16 16 -478 170 -657 219 -36 9 -85 23 -110
                30 -40 12 -116 28 -250 56 -90 18 -270 39 -338 39 -149 0 -294 -46 -366 -116
                -69 -67 -81 -103 -81 -246 0 -110 -2 -128 -16 -128 -8 0 -89 38 -180 83 -416
                212 -709 409 -934 631 -151 148 -228 251 -292 386 -46 98 -52 114 -68 193 -75
                358 166 680 660 884 104 43 149 59 200 73 17 5 62 17 100 28 62 17 131 33 275
                63 235 49 759 72 1112 49z"></path>
                <path fill="#000000" d="M1359 8488 c-57 -44 -65 -51 -274 -245 -144 -133 -324 -328 -455
                -491 -399 -499 -600 -992 -600 -1474 0 -204 49 -464 121 -633 199 -476 506
                -842 1054 -1262 49 -37 353 -243 359 -243 3 0 31 -17 63 -38 32 -21 65 -42 73
                -46 8 -4 24 -12 35 -19 160 -97 730 -387 761 -387 2 0 56 -25 119 -55 64 -30
                117 -55 120 -55 2 0 39 -16 82 -36 43 -20 92 -42 108 -49 158 -70 197 -88 333
                -151 40 -19 75 -34 78 -34 2 0 44 -19 92 -41 48 -23 119 -56 157 -74 198 -93
                264 -124 415 -199 91 -46 190 -96 220 -113 30 -17 80 -44 110 -61 376 -203
                666 -409 884 -628 160 -160 257 -296 322 -454 67 -161 84 -246 84 -415 0 -270
                -79 -506 -280 -830 -44 -72 -80 -135 -80 -141 0 -6 7 -17 15 -24 13 -11 22
                -10 48 3 29 15 141 91 177 120 8 7 60 46 115 87 219 163 501 428 677 635 206
                243 387 528 487 770 19 44 39 94 46 110 30 69 75 224 100 340 35 163 47 294
                42 477 -7 320 -67 570 -205 858 -135 282 -378 603 -607 799 -55 47 -102 89
                -105 92 -11 14 -270 199 -340 242 -211 134 -316 195 -575 334 -90 48 -457 231
                -586 291 -46 22 -127 60 -179 84 -52 25 -122 58 -155 73 -33 15 -91 42 -130
                60 -77 36 -153 71 -230 105 -27 12 -90 42 -140 65 -87 41 -123 58 -320 148
                -109 50 -676 334 -760 380 -249 138 -364 205 -402 233 -17 13 -34 24 -37 24
                -6 0 -190 124 -241 163 -141 108 -241 193 -315 267 -351 349 -465 695 -353
                1075 14 48 19 62 48 130 14 34 62 128 97 192 18 33 21 46 12 60 -17 28 -29 26
                -85 -19z m-96 -220 c-22 -29 -64 -178 -82 -289 -53 -326 98 -684 421 -994 80
                -78 215 -193 281 -241 62 -45 372 -251 387 -257 8 -4 22 -11 30 -17 37 -24
                151 -90 155 -90 3 0 24 -12 46 -26 78 -49 523 -276 744 -380 55 -25 138 -64
                185 -86 47 -22 117 -55 155 -73 82 -39 199 -93 270 -125 28 -12 79 -36 115
                -52 36 -17 90 -42 120 -55 76 -35 188 -87 295 -138 50 -24 153 -73 230 -110
                149 -70 396 -194 492 -246 32 -17 87 -47 123 -66 36 -20 116 -65 178 -102 62
                -36 120 -71 130 -76 66 -39 343 -226 402 -272 386 -301 665 -663 820 -1063 48
                -124 59 -158 89 -275 62 -245 62 -644 0 -890 -6 -22 -17 -67 -26 -100 -52
                -202 -161 -446 -296 -665 -62 -101 -191 -285 -221 -316 -6 -6 -29 -33 -51 -60
                -147 -180 -420 -443 -605 -584 -25 -19 -47 -37 -50 -40 -3 -4 -30 -25 -61 -48
                -52 -39 -79 -51 -79 -35 0 3 29 65 64 137 113 232 176 466 176 656 0 62 -27
                236 -49 310 -33 117 -121 295 -188 380 -215 277 -434 465 -818 703 -123 76
                -152 93 -265 155 -36 20 -87 48 -115 64 -63 35 -421 213 -540 268 -49 23 -121
                57 -160 75 -131 62 -226 105 -230 105 -3 0 -70 30 -210 95 -120 56 -207 95
                -210 95 -2 0 -43 18 -90 40 -47 22 -88 40 -91 40 -2 0 -41 18 -87 39 -45 21
                -120 56 -167 77 -107 49 -475 233 -555 277 -33 18 -82 46 -110 61 -242 135
                -595 379 -800 554 -135 116 -376 360 -450 456 -205 268 -353 547 -407 771
                -105 436 -57 835 155 1275 77 160 264 446 385 592 11 12 48 57 82 98 35 41
                155 167 267 279 150 150 207 201 215 193 7 -7 8 -15 1 -24z"></path>
                <path fill="#000000" d="M1055 2788 c-25 -12 -975 -1911 -975 -1949 0 -21 9 -30 53 -51 58
                -29 154 -73 207 -95 19 -8 49 -20 65 -28 87 -38 274 -114 335 -135 19 -7 49
                -19 65 -26 55 -24 445 -155 565 -190 209 -62 200 -59 340 -94 234 -58 318 -77
                540 -119 136 -26 393 -60 545 -71 72 -5 148 -14 170 -20 51 -14 606 -7 730 9
                176 23 311 42 360 52 344 72 526 136 770 273 104 59 257 187 329 276 57 70
                119 180 148 260 28 78 51 235 44 300 -28 246 -120 449 -310 685 -67 83 -238
                254 -341 341 -193 164 -617 444 -669 444 -27 0 -34 -32 -20 -95 46 -212 -23
                -320 -239 -376 -93 -24 -392 -26 -536 -4 -277 43 -319 52 -606 120 -60 15
                -130 33 -155 40 -25 8 -72 21 -105 30 -66 19 -109 31 -170 50 -22 6 -65 19
                -95 28 -90 27 -558 184 -650 217 -47 18 -96 36 -110 40 -43 15 -164 60 -185
                69 -47 22 -81 28 -100 19z m88 -92 c65 -27 93 -38 152 -57 33 -11 68 -24 77
                -30 10 -5 23 -9 29 -9 6 0 25 -6 42 -14 18 -8 75 -28 127 -46 52 -17 104 -35
                115 -40 11 -4 101 -34 200 -65 99 -32 198 -63 220 -70 22 -7 67 -21 100 -30
                33 -9 71 -20 85 -25 14 -4 54 -16 90 -25 36 -9 76 -21 90 -26 25 -8 233 -58
                345 -83 703 -157 1125 -116 1246 119 14 28 22 68 26 133 5 75 9 92 22 92 15 0
                222 -131 335 -210 314 -224 594 -519 713 -755 80 -158 103 -240 110 -397 5
                -105 -9 -198 -38 -268 -53 -123 -110 -208 -201 -297 -66 -66 -170 -149 -208
                -166 -8 -4 -28 -15 -45 -25 -96 -59 -142 -81 -280 -134 -166 -64 -350 -110
                -580 -147 -375 -60 -931 -55 -1410 14 -60 9 -141 20 -178 25 -38 5 -72 12 -76
                15 -5 2 -37 9 -72 15 -91 14 -296 59 -439 97 -30 7 -73 19 -95 24 -58 15 -129
                35 -220 62 -44 14 -107 32 -140 42 -33 9 -71 21 -85 27 -14 5 -70 25 -125 43
                -55 18 -111 37 -125 43 -14 6 -59 23 -100 38 -41 14 -88 32 -105 39 -16 7 -66
                27 -110 44 -44 18 -88 36 -97 41 -10 6 -22 10 -27 10 -8 0 -52 19 -166 70 -11
                5 -55 25 -98 44 -51 23 -77 40 -77 51 0 17 890 1806 911 1831 14 17 23 17 62
                0z"></path>
            </g>
        </svg>
    );
};

export default blackBorderlessLogo;