import React from 'react';

export const tutor = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 31.312 31.312" 
			className={props.className}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			style={props.style ? props.style : {enableBackground: 'new 0 0 31.312 31.312' }}>
			<rect x="23.695" y="15.25" width="5.052" height="1.878" fill="inherit"/>
			<polygon points="2.931,0.463 2.931,3.416 4.22,3.018 4.22,1.753 30.021,1.753 30.021,18.201 9.49,18.201 9.423,19.49     31.312,19.49 31.312,0.463   " fill="inherit"/>
			<circle cx="4.983" cy="7.526" r="3.821" fill="inherit"/>
			<polygon points="8.227,29.105 8.227,22.302 8.227,21.661 8.227,20.791 8.47,20.791 8.825,13.886 15.415,10.471 14.861,9.403     15.022,9.683 22.083,5.641 21.764,5.081 14.713,9.117 14.549,8.8 8.524,11.922 6.374,11.922 4.917,13.611 3.507,11.922     0.411,12.497 0.213,19.365 1.526,19.365 1.597,20.791 1.796,20.791 1.796,21.661 1.796,22.302 1.796,29.105 1.533,29.105     0,29.438 0,30.85 1.305,30.85 2.829,30.602 2.843,30.85 4.531,30.85 4.531,29.275 4.531,29.105 4.531,22.302 5.492,22.302     5.492,29.105 5.492,29.275 5.492,30.85 7.18,30.85 7.194,30.602 8.718,30.85 10.023,30.85 10.023,29.438 8.489,29.105   " fill="inherit"/>
		</svg>
	);
};