import React from 'react';

export const realEstate = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 296.096 296.096" 
			className={props.className}
			style={props.style ? props.style : {enableBackground: 'new 296.096 296.096' }}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"}>
			<path d="M101.15,175.142c6.966,0,14.634,0.68,21.634,1.966v-20.969h51v37.424c6,2.203,10.381,4.579,14.494,7.567   c7.797,5.664,12.082,13.099,12.339,21.254c9.925-2.345,19.028-6.321,26.905-11.737c2.846-1.956,6.262-3.668,8.262-5.137v-58.243   L148.047,49.23l-89.263,98.628v35.247c6-1.521,11.468-3.156,17.806-4.884C84.086,176.178,92.405,175.142,101.15,175.142z" fill="#484848"/>
			<polygon points="148.446,25.313 247.63,136.139 270.251,136.139 148.247,0 97.784,55.572 97.784,33.139 60.784,33.139    60.784,96.454 25.201,136.139 48.144,136.139  " fill="inherit"/>
			<path d="M259.083,214.931c-5.494,0-12.89,2.44-22.289,8.901c-17.182,11.811-38.215,17.012-57.433,17.012   c-15.623,0-30.045-3.435-40.23-9.546c-5.046-3.028-5.031-4.039-2.104-4.039c5.878,0,23.504,4.079,35.449,4.079   c5.878,0,10.379-0.987,11.432-3.934c4.867-13.628-14.001-18.016-33.73-26c-14.444-5.848-32.073-10.262-48.861-10.263   c-6.999,0-13.914,0.767-20.332,2.517c-29.141,7.945-50.199,13.93-56.199,15.873v76.48c12-4.413,25.9-8.642,33.81-8.642   c14.516,0,73.253,18.726,106.51,18.726c4.658,0,8.829-0.367,12.298-1.204c28.227-6.814,77.232-46.725,90.533-60.351   C274.323,227.998,271.797,214.931,259.083,214.931z" fill="#484848"/>
		</svg>
	);
};