import React from 'react';

export const Four = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			width="32px" 
			height="32px"
			{...props}
		>
			<path
				d="M409.6-328.089c0-25.134-20.378-45.511-45.511-45.511H45.511C20.378-373.6,0-353.222,0-328.089 V-9.511C0,15.622,20.378,36,45.511,36h318.578C389.222,36,409.6,15.622,409.6-9.511V-328.089z"
				fill="#E91E63"
				transform="matrix(1.25 0 0 -1.25 0 45)"
			/>
			<path
				d="M207.553-93.582h-0.694l-47.639-98.418h48.333V-93.582z M122.539-238.569 c-17.283,0-24.701,11.628-24.701,20.81c0,7.76,2.822,12.004,4.949,15.519l79.019,143.235 c7.76,14.108,17.636,20.457,35.977,20.457c20.469,0,40.573-13.062,40.573-45.158v-108.294h6.007 c13.756,0,24.69-9.182,24.69-23.29c0-14.12-10.934-23.279-24.69-23.279h-6.007v-29.992c0-18.694-7.407-27.511-25.395-27.511 c-18,0-25.407,8.818-25.407,27.511v29.992L122.539-238.569L122.539-238.569z"
				fill="#fff"
				transform="matrix(1.25 0 0 -1.25 0 45)"
			/>
		</svg>
	);
};

