import React, { Component } from 'react';
// redux-sagas
import { connect } from 'react-redux';
import { authCreator } from '../../../../../../store/actions';
// CSS
import classes from './UserAuthButton.module.css';
// JSX
import { Link } from 'react-router-dom';
import ImageFadeIn from '../../../../../UI/ImageFadeIn/ImageFadeIn';
import SVG from '../../../../../SVG/SVG';

class UserButton extends Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    state = {
        bIsListOpen: false
    }

    /**
     * Detects clicks outside React component.
     */
    onDocumentMouseDownHandler = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.closeList();
        }
    }

    /**
     * Detects ESC key presses.
     */
    onKeyDownHandler = (event) => {
        // `event.keyCode === 27` means whenever the ESC key is pressed.
        if(event.keyCode === 27) {
            this.closeList();
        }
    }

    closeList = () => {
        if (this.state.bIsListOpen) {
            this.setState( () => {
                return {
                    bIsListOpen: false
                }
            });
        }
    }

    toggleList = () => {
        this.setState( (prevState) => {
            return {
                bIsListOpen: !prevState.bIsListOpen
            }
        });
    }

    onMouseClickHandler = () => {
        if (this.props.onClick) { this.props.onClick(); }

        // Width is passed as a prop to determine breakpoint, if it's null it means the breakpoint was reached.
        if (this.props.width) {
            this.closeList();
        }
    }

    logoutHandler = () => {
        if (this.props.onClick) { this.props.onClick(); }
        this.props.onLogoutHandler();
    }

    componentDidMount() {
        document.body.addEventListener("mousedown", this.onDocumentMouseDownHandler);
        document.body.addEventListener("keydown", this.onKeyDownHandler);
    }

    componentWillUnmount() {
        document.body.removeEventListener("mousedown", this.onDocumentMouseDownHandler);
        document.body.removeEventListener("keydown", this.onKeyDownHandler);
    }

    render () {
        const list = (
            <ul className={classes.ListWrapper}>
                <Link to="/users/show/">
                    <li onClick={this.onMouseClickHandler} className={classes.ListItem}>View Profile</li>
                </Link>
                <Link to="/users/publications">
                    <li onClick={this.onMouseClickHandler} className={classes.ListItem}>Check Publications</li>
                </Link>
                <Link to="/users/edit">
                    <li onClick={this.onMouseClickHandler} className={classes.ListItem}>Edit Profile</li>
                </Link>
                <Link to="/users/feedback">
                    <li onClick={this.onMouseClickHandler} className={classes.ListItem}>Give us some feedback</li>
                </Link>
                <li onClick={this.props.onLogoutHandler} className={classes.ListItem}>Sign out</li>
            </ul>
        );

        const widescreen = (
            <li ref={this.wrapperRef} className={[this.props.className, classes.Anchor].join(' ')} >
                <button onClick={this.toggleList}>
                    {this.props.userDetails ? 
                        this.props.userDetails.photoURL ? 
                            <div className={classes.User}><ImageFadeIn draggable='false' src={this.props.userDetails.photoURL} /></div>
                            : <SVG svg='user' />
                        : <SVG svg='user' />}
                </button>
                {this.state.bIsListOpen ? 
                    list
                    : null}
            </li>
        );

        return (
            !this.props.width ? // Width is passed as a prop to determine breakpoint, if it's null it means the breakpoint was reached.
                list :
                widescreen
        );
    }
}

const mapStateToProps = (state) => {
	return {
        userDetails: state.authReducer.userDetails,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogoutHandler: () => dispatch(authCreator.authLogoutInit()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserButton);
