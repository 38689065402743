import React from 'react';

const servicePost = () => {
    return (
        <svg viewBox="-32 1 511 511.999"
            width="36px" 
            height="36px" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="m316.773438 60h-180c-8.398438 0-15 6.597656-15 15v181c0 8.398438 6.601562 15 15 15h180c8.402343 0 15-6.601562 15-15v-181c0-8.402344-6.597657-15-15-15zm0 0" fill="#e63950" />
            <path d="m445.773438 262.898438-69 131.699218c-18.300782 34.800782-54 56.402344-92.996094 56.402344h-125.703125l-57 56.800781c-3 2.699219-6.898438 4.199219-10.5 4.199219-3.898438 0-7.796875-1.5-10.800781-4.199219l-75-74.804687c-5.699219-5.996094-5.699219-15.597656 0-21.296875l88.800781-88.800781c14.101562-14.097657 33-21.898438 53.101562-21.898438h156c4.5 0 8.699219 2.398438 11.101563 6.300781 2.398437 3.898438 2.398437 8.699219.296875 12.898438-6.601563 12.902343-14.398438 27.902343-17.097657 32.699219-2.703124 5.101562-7.800781 8.101562-13.199218 8.101562h-102.003906c-8.398438 0-15 6.601562-15 15s6.601562 15 15 15h102.003906c16.796875 0 31.796875-9.300781 39.597656-24l46.199219-87.902344c12.300781-23.398437 36.601562-38.097656 63-38.097656 5.101562 0 9.902343 2.699219 12.601562 7.199219 3 4.5 3 10.199219.597657 14.699219zm0 0" fill="#ffa78f" />
            <path d="m283.777344 361h-57.003906v-60h75.902343c4.5 0 8.699219 2.398438 11.101563 6.300781 2.398437 3.898438 2.398437 8.699219.296875 12.898438-6.601563 12.902343-14.398438 27.902343-17.097657 32.699219-2.703124 5.101562-7.800781 8.101562-13.199218 8.101562zm0 0" fill="#f89580" />
            <path d="m445.773438 262.898438-69 131.699218c-18.300782 34.800782-54 56.402344-92.996094 56.402344h-57.003906v-60h57.003906c16.796875 0 31.796875-9.300781 39.597656-24l46.199219-87.902344c12.300781-23.398437 36.601562-38.097656 63-38.097656 5.101562 0 9.902343 2.699219 12.601562 7.199219 3 4.5 3 10.199219.597657 14.699219zm0 0" fill="#f89580" />
            <path d="m331.773438 75v181c0 8.398438-6.597657 15-15 15h-90v-211h90c8.402343 0 15 6.597656 15 15zm0 0" fill="#cc2e43" />
            <path d="m271.773438 45v60c0 8.398438-6.597657 15-15 15-8.398438 0-15-6.601562-15-15v-60c0-8.402344-6.597657-15-15-15-8.398438 0-15 6.597656-15 15v60c0 8.398438-6.597657 15-15 15-8.398438 0-15-6.601562-15-15v-60c0-24.902344 20.101562-45 45-45 24.902343 0 45 20.097656 45 45zm0 0" fill="#697c86" />
            <path d="m226.773438 30v-30c24.902343 0 45 20.097656 45 45v60c0 8.398438-6.597657 15-15 15-8.398438 0-15-6.601562-15-15v-60c0-8.402344-6.597657-15-15-15zm0 0" fill="#465a61" />
        </svg>
    );
}

export default servicePost;