import React from 'react';

export const fit = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 512 512" 
			className={props.className}
			style={props.style ? props.style : {enableBackground: 'new 0 0 512 512' }}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"}>
			<path d="M495.841,101.383h-13.617c0-25.469,0-19.966,0-45.595c0-4.715-3.822-8.537-8.537-8.537h-23.323    c-4.715,0-8.537,3.822-8.537,8.537c0,25.63,0,20.123,0,45.595h-29.811c1.243-13.058-8.373-24.492-21.113-25.839    c-13.105-1.382-24.726,8.197-26.09,21.099l-0.501,4.739h-41.088c-12.121,26.171-38.282,43.068-67.261,43.068    c-29.378,0-55.317-17.281-67.261-43.068h-40.938l-0.501-4.739c-1.375-12.998-13.102-22.474-26.09-21.1    c-12.723,1.347-22.358,12.768-21.113,25.841H70.171c0-25.469,0-19.966,0-45.595c0-4.715-3.822-8.537-8.537-8.537H38.311    c-4.715,0-8.537,3.822-8.537,8.537c0,25.63,0,20.123,0,45.595H16.159C7.234,101.383,0,108.617,0,117.542    c0,8.924,7.234,16.159,16.159,16.159h13.616c0,25.469,0,19.966,0,45.595c0,4.715,3.823,8.537,8.537,8.537h23.323    c4.715,0,8.537-3.823,8.537-8.537c0-25.63,0-20.123,0-45.595h33.291c1.023,9.669,8.253,78.036,9.321,88.147    c2.145,20.289,27.705,28.867,41.462,13.117l33.346-38.161v90.784l-86.282,35.055c-10.072,4.092-17.246,13.183-18.886,23.93    L65.21,459.425c-2.57,16.845,9.002,32.582,25.846,35.151s32.582-9.002,35.151-25.846l14.572-95.523l79.818-32.429h70.854    l79.818,32.429l14.572,95.523c2.57,16.846,18.307,28.415,35.151,25.846c16.844-2.57,28.415-18.307,25.846-35.151l-17.215-112.853    c-1.64-10.747-8.814-19.837-18.886-23.93l-86.966-35.334V195.99l34.055,38.974c13.39,15.326,39.255,7.732,41.462-13.117    c1.067-10.094,8.302-78.495,9.323-88.147h33.217c0,25.469,0,19.966,0,45.595c0,4.715,3.823,8.537,8.537,8.537h23.323    c4.715,0,8.537-3.823,8.537-8.537c0-25.63,0-20.123,0-45.595h13.617c8.924,0,16.159-7.234,16.159-16.159    C512,108.617,504.766,101.383,495.841,101.383z M154.261,162.83c-1.279-12.098-1.815-17.155-3.081-29.13h28.534    C169.376,145.533,164.743,150.835,154.261,162.83z M357.812,162.83c-11.794-13.497,3.356,3.84-25.454-29.13h28.534    C358.5,156.32,359.089,150.748,357.812,162.83z" fill="#484848"/>
			<circle cx="255.963" cy="70.351" r="53.284" fill="inherit"/>
		</svg>
	);
};