import React from 'react';

export const health = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 512 512" 
			className={props.className}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			style={props.style ? props.style : {enableBackground: 'new 0 0 512 512' }}>
			<path d="M376,33c-60.659,0-99.595,42.012-120,79.849C235.595,75.011,196.659,33,136,33C59.037,33,0,95.446,0,178.268    c0,21.61,4.327,41.561,12.294,60.732h114.434l25.854-51.709c5.098-10.166,21.738-10.166,26.836,0L226,280.456l16.582-33.164    c5.098-10.166,21.738-10.166,26.836,0L286,280.456l46.582-93.164c5.098-10.166,21.738-10.166,26.836,0L385.272,239h114.434    C507.673,219.829,512,199.878,512,178.268C512,95.446,452.963,33,376,33z" fill="#484848"/>
			<path d="M376,269c-5.684,0-10.869-3.208-13.418-8.291L346,227.545l-46.582,93.164C296.869,325.792,291.684,329,286,329    c-5.684,0-10.869-3.208-13.418-8.291L256,287.545l-16.582,33.164c-5.098,10.166-21.738,10.166-26.836,0L166,227.545    l-16.582,33.164C146.869,265.792,141.684,269,136,269H28.037c30.873,48.574,86.474,94.713,156.024,153.413    c19.526,16.479,40.298,34.014,62.109,52.925c2.827,2.446,6.328,3.662,9.829,3.662s7.002-1.216,9.829-3.662    c21.812-18.911,42.583-36.445,62.109-52.925c69.551-58.7,125.151-104.839,156.024-153.413H376z" fill="#484848"/>
		</svg>
	);
};