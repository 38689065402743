import React from 'react';

export const homeOutdoor = (props) => {
	return (
		<svg 
			viewBox="0 0 567.38 567.38" 
			className={props.className}
			style={props.style ? props.style : {enableBackground: 'new 0 0 567.38 567.38' }}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"} >
			<path d="M554.42,324.741h-2.081c-13.116,0-14.227,12.431-14.278,15.675v22.055c0,6.749-6.367,0.633-6.905,0.096l-48.976-48.442    L292.768,123.745h-0.017c-9.604-9.646-18.98-0.161-19.119-0.009L74.536,321.817l-0.009,0.018l-2.398,2.395    c-0.56,0.62-1.193,1.457-1.822,2.308l-33.674,33.313c-7.213,7.122-7.312-0.017-7.312-0.017v-21.335    c0-12.544-9.052-13.688-11.893-13.74H14.23c0,0-14.23-0.278-14.23,14.152v25.381v31.792v34.229c0,0,0,17.627,13.181,17.627h2.966    c2.763-0.087,13.186-1.449,13.186-17.817v-15.059c0-14.01,6.614-20.333,6.614-20.333l23.859-23.595    c1.128-1.059,5.127-4.511,5.127,0.286v60.903c0.061,3.843,1.466,15.614,16.173,15.614h99.8h5.335    c17.487,0,22.029-12.942,22.228-13.55l70.324-201.079h0.009c0,0,4.381-9.693,7.859,0c0,0.026,0,0.026,0.009,0.035l71.325,199.179    c0,0,4.823,15.415,24.844,15.415h1.545h98.264c16.117,0,17.696-11.884,17.783-15.874v-53.4c0,0,0.85-11.416,12.369-0.018    l19.032,18.824c0,0,6.229,6.297,6.229,16.125v20.628c0.052,3.184,1.093,13.706,13.012,13.706h0.832    c15.476,0.858,15.476-19.482,15.476-19.482v-9.282v-20.724v-55.556C567.38,326.381,557.595,324.819,554.42,324.741L554.42,324.741    z" fill="#484848" />
		</svg>
	);
};