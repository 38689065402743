import React from 'react';

export const electrical = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 959.759 959.76"
			className={props.className}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			style={props.style ? props.style : {enableBackground: 'new 959.759 959.76' }}>
			<g>
				<path d="M239.898,953.699l307.2-308.6c12.601-12.6,3.601-34.1-14.2-34.1h-24.5c-16.699,0-26.1-19.301-15.699-32.4l162.899-205.4   c10.4-13.1,1.101-32.4-15.7-32.4h-81.899c-16.5,0-25.9-18.8-16-32L750.098,32c9.9-13.2,0.5-32-16-32h-329.1   c-7.601,0-14.601,4.3-17.9,11.1l-160.9,325.5c-6.6,13.3,3.1,28.9,17.9,28.9h74.2c14.8,0,24.5,15.6,17.9,28.9l-110,222.6   c-6.6,13.301,3.1,28.9,17.9,28.9h69.9c14.8,0,24.399,15.4,18,28.699L207.798,930.9   C197.898,951.199,223.998,969.699,239.898,953.699z" fill="#484848"/>
			</g>
		</svg>
	);
};