import React from 'react';

const currency = (props) => {
	return (
		<svg 
            xmlns='http://www.w3.org/2000/svg' 
			x='0px' 
			y='0px'
			viewBox={props.viewBox ? props.viewBox : '0 0 500 500' }
			aria-hidden='true' 
			focusable='false'
			role='presentation' 
			width={props.width ? props.width : null} 
			height={props.height ? props.height : null} 	
			fill={props.fill ? props.fill : null}
			style={props.style ? props.style : {display: 'block', fill: '#007791'}}>
            <defs>
                <clipPath id="priceRatingPath">
                    <path d="M309.43,219.944c-19-10.5-39.2-18.5-59.2-26.8c-11.6-4.8-22.7-10.4-32.5-18.2c-19.3-15.4-15.6-40.4,7-50.3    c6.4-2.8,13.1-3.7,19.9-4.1c26.2-1.4,51.1,3.4,74.8,14.8c11.8,5.7,15.7,3.9,19.7-8.4c4.2-13,7.7-26.2,11.6-39.3    c2.6-8.8-0.6-14.6-8.9-18.3c-15.2-6.7-30.8-11.5-47.2-14.1c-21.4-3.3-21.4-3.4-21.5-24.9c-0.1-30.3-0.1-30.3-30.5-30.3    c-4.4,0-8.8-0.1-13.2,0c-14.2,0.4-16.6,2.9-17,17.2c-0.2,6.4,0,12.8-0.1,19.3c-0.1,19-0.2,18.7-18.4,25.3c-44,16-71.2,46-74.1,94    c-2.6,42.5,19.6,71.2,54.5,92.1c21.5,12.9,45.3,20.5,68.1,30.6c8.9,3.9,17.4,8.4,24.8,14.6c21.9,18.1,17.9,48.2-8.1,59.6    c-13.9,6.1-28.6,7.6-43.7,5.7c-23.3-2.9-45.6-9-66.6-19.9c-12.3-6.4-15.9-4.7-20.1,8.6c-3.6,11.5-6.8,23.1-10,34.7    c-4.3,15.6-2.7,19.3,12.2,26.6c19,9.2,39.3,13.9,60,17.2c16.2,2.6,16.7,3.3,16.9,20.1c0.1,7.6,0.1,15.3,0.2,22.9    c0.1,9.6,4.7,15.2,14.6,15.4c11.2,0.2,22.5,0.2,33.7-0.1c9.2-0.2,13.9-5.2,13.9-14.5c0-10.4,0.5-20.9,0.1-31.3    c-0.5-10.6,4.1-16,14.3-18.8c23.5-6.4,43.5-19,58.9-37.8C386.33,329.544,370.03,253.444,309.43,219.944z" />
                </clipPath>
            </defs>
            <rect x="0" y="0" fill="rgb(217,217,217)" width="100%" height="100%" clipPath="url(#priceRatingPath)" />
            <rect x="0" y="0%" fill="#007791" width={props.fill ? props.fill : '100%'} height="100%" clipPath="url(#priceRatingPath)" />
        </svg>
	);
};

export default currency;