import React from 'react';

export const remodel = (props) => {
	return (
		<svg
			x="0px" 
			y="0px" 
			viewBox="0 0 512 512" 
			className={props.className}
			style={props.style ? props.style : {enableBackground: 'new 0 0 512 512' }}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"}>
			<path d="m182.796875 301h28.203125v-44.605469c-14.519531 10.902344-24.476562 26.8125-28.203125 44.605469zm0 0" fill="#484848" />
			<path d="m271 512v-122.097656c3.71875-2.371094 47.886719-9.289063 58.261719-58.902344-6.441407 0-146.523438 0-146.523438 0 10.183594 48.699219 55.257813 56.988281 58.261719 58.902344v122.097656zm0 0" fill="#484848" />
			<path d="m451 302.230469-195-181.257813-195 181.257813v179.769531h-61v30h211v-101.503906c-36.285156-17.269532-60-53.992188-60-94.496094 0-86.03125 86.992188-103.6875 90-105.746094v90.746094h30v-90.746094c3.597656 2.460938 90 19.21875 90 105.746094 0 40.503906-23.714844 77.226562-60 94.496094v101.503906h211v-30h-61zm0 0" fill="#484848" />
			<path d="m301 256.394531v44.605469h28.203125c-3.726563-17.792969-13.683594-33.703125-28.203125-44.605469zm0 0" fill="#484848" />
			<path d="m0 234.421875 50.527344 36.578125 205.472656-190.984375 205.472656 190.984375 50.527344-36.578125-256-234.421875zm0 0" fill="#484848" />
			<path d="m391 83.90625 60 54.921875v-107.828125h-60zm0 0" fill="#484848" />
		</svg>
	);
};