import React from 'react';

const tools = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 420.555 420.555"
			enableBackground="new 420.555 420.555"
            className={props.className}
			style={props.style}
			width={props.width ? props.width : "30px"} 
			height={props.height ? props.height : "18px"} 
			fill={props.fill ? props.fill : "#FFF"} >
			<polygon style={props.style ? props.style : {backgroundColor: "#333"}} points="212.905,177.216 316.105,74.016 316.905,47.616 371.305,15.616 399.305,43.616 
				366.505,98.016 340.905,98.816 237.705,202.016 "/>
			<path style={props.style ? props.style : {backgroundColor: "#333"}} d="M349.705,406.016c-36,0-64.8-29.6-64.8-65.6c0-4.8,0.8-9.6,1.6-14.4l-180.8-180
				c-9.6,4.8-20,7.2-30.4,7.2c-27.2,0-52-16.8-61.6-43.2l-7.2-20l36.8,20.8c3.2,1.6,6.4,2.4,10.4,2.4c12-0.8,22.4-8,28-18.4
				c4-7.2,5.6-16,4.8-24c-0.8-6.4-4.8-12.8-10.4-16l-38.4-22.4l15.2-5.6c33.6-12,71.2,5.6,83.2,39.2c4,12,4.8,24.8,2.4,36.8
				l180.8,180.8c9.6-4.8,20-7.2,30.4-7.2c23.2,0,44,12,56,32l9.6,16.8l-48-12.8c-1.6-0.8-3.2-0.8-5.6-0.8c-13.6,1.6-24.8,12-28,25.6
				c-2.4,8-1.6,16,1.6,24c2.4,6.4,8,12,14.4,13.6l48,12.8l-16,9.6C372.905,402.816,360.905,406.016,349.705,406.016z"/>
			<polygon style={props.style ? props.style : {backgroundColor: "#333"}} points="40.105,406.016 4.905,370.816 111.305,264.416 131.305,284.416 149.705,303.616 
					47.305,406.016 43.305,406.016 	"/>
			<rect x="142.66" y="228.557" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 461.9793 300.9546)" style={props.style ? props.style : {backgroundColor: "#333"}} width="52" height="35.2"/>
			<polygon style={props.style ? props.style : {backgroundColor: "#333"}} points="157.705,310.016 100.905,253.216 120.905,234.816 180.105,294.816 164.105,310.016 
				160.905,310.016 	"/>
			<path style={props.style ? props.style : {backgroundColor: "#333"}} d="M401.705,289.216c-13.6-13.6-32-21.6-52-21.6c-9.6,0-20,2.4-28.8,5.6l-72-72l96-95.2l22.4-0.8
				c2.4,0,5.6-1.6,6.4-4l32-54.4c1.6-3.2,1.6-7.2-1.6-9.6l-28-28c-2.4-2.4-6.4-3.2-9.6-1.6l-54.4,32.8c-2.4,1.6-4,4-4,6.4l-0.8,22.4
				l-94.4,96.8l-65.6-65.6c7.2-40-20-77.6-60-84.8c-18.4-3.2-37.6,0.8-52.8,11.2c-4,2.4-4.8,7.2-2.4,11.2c0.8,0.8,1.6,1.6,2.4,2.4
				l36,20c8,4.8,9.6,18.4,3.2,29.6s-19.2,17.6-27.2,12.8l-33.6-18.4c-4-2.4-8.8-0.8-11.2,3.2c0.8,1.6,0,3.2,0,4.8
				c2.4,40,37.6,71.2,77.6,68.8c8-0.8,16.8-2.4,24-5.6l59.2,59.2l-25.6,25.6l-4.8-5.6c-6.4-6.4-16.8-6.4-23.2,0l-8,8
				c-6.4,5.6-6.4,15.2-0.8,22.4l0,0l-95.2,94.4c-6.4,6.4-6.4,16-0.8,22.4c0,0,0,0,0.8,0.8l27.2,27.2c6.4,6.4,16,6.4,22.4,0.8
				c0,0,0,0,0.8-0.8l94.4-95.2l0,0c6.4,5.6,16,5.6,22.4-0.8l8-8c6.4-6.4,6.4-16,0.8-22.4c0,0,0,0-0.8-0.8l-5.6-5.6l25.6-26.4l77.6,77.6
				c-6.4,40,20,77.6,60,84c24,4,48-4,64.8-20.8c3.2-3.2,2.4-8-0.8-11.2c-0.8-0.8-1.6-1.6-3.2-1.6l-44.8-12c-4.8-1.6-8-4.8-9.6-9.6
				c-2.4-6.4-2.4-12.8-0.8-19.2c3.2-12.8,14.4-21.6,23.2-19.2l44.8,12c0.8,0,1.6,0,2.4,0c4.8,0,8-3.2,8-8c0-1.6,0-2.4-0.8-4
				C416.105,308.416,409.705,298.016,401.705,289.216z M321.705,79.616c1.6-1.6,2.4-3.2,2.4-5.6l0.8-21.6l45.6-27.2l19.2,19.2
				l-27.2,45.6l-21.6,0.8c-2.4,0-4,0.8-5.6,2.4l-97.6,97.6l-13.6-13.6L321.705,79.616z M44.105,398.016L44.105,398.016l-27.2-27.2
				l95.2-95.2l13.6,13.6l12.8,13.6L44.105,398.016z M160.905,302.016L160.905,302.016l-10.4-10.4l0,0l-4-4l-33.6-33.6l8-8l48,48.8
				L160.905,302.016z M162.505,265.216l-13.6-13.6l25.6-25.6l13.6,13.6L162.505,265.216z M369.705,304.416c-2.4-0.8-4.8-0.8-7.2-0.8
				c-17.6,1.6-32,14.4-35.2,31.2c-2.4,9.6-2.4,20,1.6,29.6c3.2,8.8,11.2,16,20,18.4l29.6,8c-27.2,16-62.4,7.2-78.4-20
				c-7.2-12.8-9.6-28-6.4-42.4c0.8-2.4,0-5.6-2.4-7.2l-180-181.6c-2.4-2.4-6.4-3.2-9.6-0.8c-8,4-17.6,6.4-27.2,6.4
				c-24,0-45.6-15.2-53.6-37.6l18.4,10.4c4,2.4,8.8,4,13.6,4c15.2-0.8,28-9.6,34.4-22.4c11.2-19.2,6.4-42.4-9.6-51.2l-23.2-12.8
				c29.6-10.4,62.4,4.8,72.8,34.4c4,10.4,4,21.6,1.6,32c-0.8,2.4,0,5.6,2.4,7.2l180.8,180.8c2.4,2.4,6.4,3.2,9.6,1.6
				c8-4,17.6-6.4,26.4-6.4c20,0,39.2,10.4,49.6,28L369.705,304.416z"/>
		</svg>
	);
};

export default tools;