import React from 'react';

export const plumbing = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px"
			viewBox="0 0 200 100"
			className={props.className}
			style={props.style}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"}>
			<path d="M205.153,127.687v12.744c0,4.143-3.357,7.5-7.5,7.5h-37.029c-4.143,0-7.5-3.357-7.5-7.5v-12.744  c0-3.633,2.582-6.66,6.011-7.351v-14.346c0-2.56-2.083-4.643-4.643-4.643h-1.524c-6.898,12.417-19.909,20.193-34.423,20.193  s-27.524-7.776-34.423-20.193H27.174c-4.143,0-7.5-3.357-7.5-7.5V69.081c0-4.143,3.357-7.5,7.5-7.5h57.719  c4.748-7.771,12.021-13.585,20.452-16.592v-9.314c0-0.514,0.052-1.015,0.151-1.5H85.363c-4.143,0-7.5-3.357-7.5-7.5v-10.74  c0-4.143,3.357-7.5,7.5-7.5h19.981V7.5c0-4.143,3.357-7.5,7.5-7.5h11.4c4.143,0,7.5,3.357,7.5,7.5v0.935h19.982  c4.143,0,7.5,3.357,7.5,7.5v10.74c0,4.143-3.357,7.5-7.5,7.5h-20.133c0.098,0.485,0.151,0.986,0.151,1.5v9.314  c8.431,3.007,15.704,8.82,20.452,16.592h14.438c17.659,0,32.026,14.366,32.026,32.025v26.656  C202.326,120.755,205.153,123.887,205.153,127.687z M197.35,199.705c-0.09-0.451-0.22-0.887-0.387-1.305l-11.15-35.521  c-0.98-3.127-3.879-5.254-7.155-5.254s-6.175,2.127-7.155,5.254l-11.316,36.051c-0.057,0.18-0.106,0.361-0.148,0.543  c-0.6,1.902-0.902,3.859-0.902,5.831c0,10.765,8.758,19.522,19.522,19.522s19.522-8.758,19.522-19.522  C198.18,203.413,197.9,201.535,197.35,199.705z"/>
		</svg>
	);
};