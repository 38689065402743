import React from 'react';

export const insurance = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="-24 0 496 496" 
			className={props.className}
			style={props.style ? props.style : {enableBackground: 'new -24 0 496 496' }}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"}>
			<path d="m16 48h240v56c0 4.425781 3.574219 8 8 8h56v64h16v-72c0-2.214844-.902344-4.214844-2.351562-5.664062l-63.976563-63.976563c-1.457031-1.457031-3.457031-2.359375-5.671875-2.359375h-216v-16h304v176h16v-184c0-4.425781-3.574219-8-8-8h-320c-4.425781 0-8 3.574219-8 8v24h-24c-4.425781 0-8 3.574219-8 8v448c0 4.425781 3.574219 8 8 8h272v-16h-264zm256 11.3125 36.6875 36.6875h-36.6875zm0 0" fill="#484848" />
			<path d="m440 240h-12.046875c-35.722656 0-69.320313-15.734375-92.183594-43.167969l-1.617187-1.945312c-3.03125-3.65625-9.265625-3.65625-12.296875 0l-1.617188 1.9375c-22.871093 27.441406-56.46875 43.175781-92.191406 43.175781h-12.046875c-4.425781 0-8 3.574219-8 8v129.566406c0 31.503906 16.992188 60.785156 44.34375 76.410156l71.6875 40.96875c1.226562.703126 2.601562 1.054688 3.96875 1.054688s2.742188-.351562 3.96875-1.054688l71.6875-40.96875c27.351562-15.625 44.34375-44.90625 44.34375-76.410156v-129.566406c0-4.425781-3.574219-8-8-8zm-8 137.566406c0 25.777344-13.894531 49.730469-36.28125 62.511719l-67.71875 38.714844-67.71875-38.703125c-22.386719-12.792969-36.28125-36.746094-36.28125-62.523438v-121.566406h4.046875c38.175781 0 74.226563-15.863281 99.953125-43.769531 25.71875 27.90625 61.777344 43.769531 99.953125 43.769531h4.046875zm0 0" fill="#484848" />
			<path d="m328 264c-48.519531 0-88 39.480469-88 88s39.480469 88 88 88 88-39.480469 88-88-39.480469-88-88-88zm0 160c-39.703125 0-72-32.296875-72-72s32.296875-72 72-72 72 32.296875 72 72-32.296875 72-72 72zm0 0" fill="#484848" />
			<path d="m324.183594 376.871094-14.527344-14.527344-11.3125 11.3125 24 24c1.511719 1.519531 3.558594 2.34375 5.65625 2.34375.648438 0 1.304688-.078125 1.953125-.238281 2.734375-.691407 4.894531-2.769531 5.710937-5.464844l24-80-15.328124-4.59375zm0 0" fill="#484848" />
			<path d="m32 128h208v16h-208zm0 0" fill="#484848" />
			<path d="m32 160h208v16h-208zm0 0" fill="#484848" />
			<path d="m32 192h208v16h-208zm0 0" fill="#484848" />
			<path d="m32 224h160v16h-160zm0 0" fill="#484848" />
			<path d="m32 256h160v16h-160zm0 0" fill="#484848" />
			<path d="m32 288h160v16h-160zm0 0" fill="#484848" />
			<path d="m32 384h160v16h-160zm0 0" fill="#484848" />
			<path d="m32 416h160v16h-160zm0 0" fill="#484848" />
			<path d="m32 448h160v16h-160zm0 0" fill="#484848" />
			<path d="m40 64h16v16h-16zm0 0" fill="#484848" />
			<path d="m72 64h16v16h-16zm0 0" fill="#484848" />
			<path d="m104 64h16v16h-16zm0 0" fill="#484848" />
			<path d="m136 64h104v16h-104zm0 0" fill="#484848" />
		</svg>
	);
};