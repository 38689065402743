import React from 'react';

export const technology = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 512 512" 
			className={props.className}
			style={props.style ? props.style : {enableBackground: 'new 0 0 512 512' }}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"}>
			<path d="M346,452h-15v-30H181v30h-15c-24.853,0-45,20.147-45,45c0,8.284,6.716,15,15,15h240c8.284,0,15-6.716,15-15    C391,472.147,370.853,452,346,452z" fill="#484848" />
			<path d="M0,332v15c0,24.814,20.186,45,45,45h422c24.814,0,45-20.186,45-45v-15H0z" fill="#484848" />
			<polygon points="331.22,92.23 285.037,120.057 331,147.832 377.033,120.015   " fill="#484848" />
			<polygon points="346,173.815 346,229.413 391,202.211 391,146.622   " fill="#484848" />
			<path d="M467,0H45C20.186,0,0,20.186,0,45v257h512V45C512,20.186,491.814,0,467,0z M166,241h-61c-8.291,0-15-6.709-15-15    s6.709-15,15-15h61c8.291,0,15,6.709,15,15S174.291,241,166,241z M166,181h-61c-8.291,0-15-6.709-15-15s6.709-15,15-15h61    c8.291,0,15,6.709,15,15S174.291,181,166,181z M166,121h-61c-8.291,0-15-6.709-15-15s6.709-15,15-15h61c8.291,0,15,6.709,15,15    S174.291,121,166,121z M421.001,210.678H421c0,5.259-2.754,10.122-7.236,12.832l-75,45.322C335.755,270.088,334.018,271,331,271    c-3.001,0-4.753-0.912-7.764-2.168l-75-45.322c-4.482-2.71-7.236-7.573-7.236-12.832v-90.645c1.002-5.7,1.588-9.056,6.94-12.512    c3.021-1.952,78.602-47.666,75.575-45.657c4.761-2.871,10.752-2.886,15.513,0.015c0.305,0.185,0.522,0.318,0.693,0.422    c-1.981-1.203-5.427-3.293,74.413,45.266c4.135,2.688,6.376,6.561,6.649,11.638c0.016,0.293,0.218,0.533,0.218,0.828V210.678z" fill="#484848" />
			<polygon points="271,146.624 271,202.211 316,229.413 316,173.822   " fill="#484848" />
		</svg>
	);
};