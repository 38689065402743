import React from 'react';

const myAccount = () => {
    return (
        <svg viewBox="0 0 464 464" 
            width="36px" 
            height="36px" 
            xmlns="http://www.w3.org/2000/svg">
            <path d="m0 232c-.117188 67.261719 29.09375 131.234375 80 175.199219v-71.199219c0-53.019531 42.980469-96 96-96h56v-240c-128.128906 0-232 103.871094-232 232zm0 0" fill="#e6e7e8" />
            <path d="m232 0v240h56c53.019531 0 96 42.980469 96 96v71.199219c73.160156-63.433594 99.214844-165.644531 65.351562-256.363281-33.863281-90.71875-120.519531-150.8554692-217.351562-150.835938zm0 0" fill="#f1f2f2" />
            <path d="m160 136c0 39.765625 32.234375 72 72 72v-144c-39.765625 0-72 32.234375-72 72zm0 0" fill="#2b3990" />
            <path d="m232 64v144c39.765625 0 72-32.234375 72-72s-32.234375-72-72-72zm0 0" fill="#434f9c" />
            <path d="m176 240c-53.019531 0-96 42.980469-96 96v71.199219c42.113281 36.722656 96.125 56.90625 152 56.800781v-224zm0 0" fill="#2b3990" />
            <path d="m288 240h-56v224c55.875.105469 109.886719-20.078125 152-56.800781v-71.199219c0-53.019531-42.980469-96-96-96zm0 0" fill="#434f9c" />
        </svg>
    );
}

export default myAccount;