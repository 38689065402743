import React from 'react';

export const pestControl = (props) => {
	return (
		<svg 
			x="0px" 
			y="0px" 
			viewBox="0 0 49.999 49.999"
			className={props.className}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			style={props.style ? props.style : {enableBackground: 'new 0 0 49.999 49.999' }} >
			<path d="M48.681,42.295l-8.925-8.904c-0.045-0.045-0.098-0.078-0.145-0.11c-0.802,1.233-1.761,2.405-2.843,3.487    c-1.081,1.082-2.255,2.041-3.501,2.845c0.044,0.046,0.077,0.1,0.122,0.144l8.907,8.924c1.763,1.76,4.626,1.758,6.383,0    C50.438,46.921,50.439,44.057,48.681,42.295z" fill="#484848" />
			<path d="M35.496,6.079C27.388-2.027,14.198-2.027,6.089,6.081c-8.117,8.106-8.118,21.306-0.006,29.415    c8.112,8.105,21.305,8.105,29.413-0.001C43.604,27.387,43.603,14.186,35.496,6.079z M9.905,31.678    C3.902,25.675,3.904,15.902,9.907,9.905c6.003-6.002,15.77-6.002,21.771-0.003c5.999,6,5.997,15.762,0,21.774    C25.676,37.66,15.91,37.682,9.905,31.678z" fill="#484848" />
			<path d="M31.028,24.606l-3.797-3.752h-0.973c-0.062-0.721-0.17-1.425-0.369-2.068l2.604-3.539    c0.285-0.389,0.202-0.937-0.187-1.223c-0.391-0.286-0.938-0.203-1.224,0.186l-2.025,2.753c-0.598-1-1.366-1.796-2.263-2.274    c0.114-0.236,0.181-0.495,0.181-0.768c0-1.076-0.979-1.95-2.188-1.95c-1.21,0-2.189,0.874-2.189,1.95    c0,0.273,0.067,0.532,0.181,0.768c-0.896,0.478-1.663,1.274-2.262,2.274l-2.024-2.753c-0.287-0.389-0.834-0.473-1.225-0.186    c-0.389,0.286-0.473,0.833-0.187,1.223l2.604,3.539c-0.199,0.644-0.308,1.348-0.369,2.068h-0.973l-3.796,3.752    c-0.344,0.339-0.348,0.894-0.007,1.237c0.339,0.345,0.893,0.349,1.236,0.007l3.285-3.247h0.254    c0.062,0.721,0.17,1.422,0.369,2.067l-2.604,3.539c-0.286,0.391-0.203,0.938,0.187,1.225c0.391,0.284,0.938,0.203,1.225-0.187    l2.024-2.753c1.016,1.699,2.542,2.807,4.271,2.807c1.729,0,3.254-1.107,4.27-2.807l2.025,2.753    c0.171,0.233,0.436,0.356,0.705,0.356c0.181,0,0.361-0.056,0.519-0.17c0.39-0.287,0.472-0.834,0.187-1.225l-2.604-3.539    c0.199-0.644,0.309-1.345,0.369-2.067h0.254l3.285,3.247c0.171,0.17,0.394,0.254,0.615,0.254c0.226,0,0.452-0.088,0.623-0.261    C31.375,25.499,31.372,24.945,31.028,24.606z" fill="#484848" />
		</svg>
	);
};