import React from 'react';

export const autoServices = (props) => {
	return (
		<svg 
			x="0px"
			y="0px" 
			viewBox="0 0 41.378 41.378" 
			className={props.className}
			style={props.style ? props.style : {enableBackground: 'new 0 0 41.378 41.378' }}
			width={props.width ? props.width : "16px"} 
			height={props.height ? props.height : "16px"} 
			fill={props.fill ? props.fill : "#484848"}>
			<g>
				<path d="M40.897,16.601c-0.257-2.263-6.35,0-6.35,0l-0.359,3.22c-0.133-0.076-0.268-0.15-0.41-0.213l-1.539-6.715    c-0.564-3.197-2.977-5.552-6.494-5.431h-9.037c-0.055,0.375-0.143,0.738-0.252,1.093h9.899c2.053,0,3.934,1.321,4.448,3.502    l1.653,6.63c-0.104-0.003-0.207-0.013-0.312-0.013H12.978l0.005,6.463h11.873v1.445H12.984l0.001,0.422h11.751v1.448H12.985    l0.004,5.43h12.677v3.439c0,1.397,1.729,2.532,3.86,2.532s3.857-1.134,3.857-2.532v-3.837c1.821-0.688,3.089-2.193,3.089-3.944    v-6.153c0-1.36-0.768-2.571-1.957-3.366l6.382-0.345C40.897,19.676,41.153,18.862,40.897,16.601z M33.586,28.344H26.62v-3.312    h6.966V28.344z" fill="#484848" />
				<rect x="12.808" y="27.003" width="0.177" height="1.448" fill="#484848" />
				<rect x="12.929" y="25.137" width="0.055" height="1.445" fill="#484848" />
				<path d="M15.337,6.488C15.331,3.71,13.821,1.29,11.572,0v5.993L7.851,8.141L4.127,5.986V0c-2.245,1.29-3.758,3.71-3.76,6.488    c0,2.913,1.67,5.437,4.104,6.676v0.032v24.649c0,0.976,0.393,1.859,1.033,2.5c0.639,0.64,1.521,1.029,2.499,1.033    c1.944,0,3.524-1.578,3.531-3.532l-0.015-24.839C13.798,11.725,15.334,9.283,15.337,6.488z M8.781,39.409    c-0.526,0.528-1.382,0.528-1.912-0.001c-0.522-0.524-0.521-1.376,0.005-1.904c0.529-0.526,1.382-0.526,1.904-0.004    C9.307,38.029,9.308,38.882,8.781,39.409z" fill="#484848" />
			</g>
		</svg>
	);
};