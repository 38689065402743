import React from 'react';

const other = () => {
	return (
		<svg viewBox="0 0 512 512"
			width="36px" 
			height="36px"
			xmlns="http://www.w3.org/2000/svg">
			<path fill="#2196F3" d="M418.88,344.021l-54.869-19.093c-4.015-1.393-8.473-0.27-11.349,2.859  c-24.328,28.17-59.449,44.719-96.661,45.547c-37.201-0.828-72.313-17.368-96.64-45.525c-2.876-3.129-7.335-4.252-11.349-2.859  l-54.869,19.093C37.383,363.423,0.006,415.978,0,475.008v15.659c0,5.891,4.776,10.667,10.667,10.667h490.667  c5.891,0,10.667-4.776,10.667-10.667v-15.659C512.006,415.976,474.637,363.411,418.88,344.021z" />
			<path fill="#FFE082" d="M384,141.44c0-0.917,0-1.835,0-2.773c0-70.692-57.308-128-128-128s-128,57.308-128,128  c0,0.917,0,1.856,0,2.773c-15.303,12.086-23.353,31.165-21.333,50.56c0,29.675,13.099,52.224,30.699,53.333  C156.715,309.589,203.499,352,256,352s99.264-42.453,118.635-106.667c17.6-1.109,30.699-23.659,30.699-53.333  C407.353,172.605,399.303,153.526,384,141.44z" />
			<path fill="#F44336" d="M245.333,223.808c-5.202,0.061-9.657-3.712-10.453-8.853c-0.421-18.167,9.884-34.882,26.304-42.667   c11.925-6.689,18.382-20.132,16.149-33.621c1.027-10.755-6.86-20.307-17.615-21.333c-1.237-0.118-2.482-0.118-3.718,0   c-10.755-1.027-20.307,6.86-21.333,17.615c-0.118,1.237-0.118,2.482,0,3.718c0,5.891-4.776,10.667-10.667,10.667   c-5.891,0-10.667-4.776-10.667-10.667c-1.049-22.515,16.351-41.617,38.866-42.667c1.266-0.059,2.534-0.059,3.801,0   c22.515-1.049,41.617,16.351,42.667,38.866c0.059,1.266,0.059,2.534,0,3.801c2.241,20.974-8.13,41.289-26.432,51.776   c-17.067,10.389-16.363,21.163-16.32,21.611c0.507,5.763-3.61,10.904-9.344,11.669C246.16,223.774,245.747,223.803,245.333,223.808   z" />
			<circle fill="#F44336" cx="245.333" cy="256" r="10.667" />
		</svg>
	);
}

export default other;