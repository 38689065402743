import React from 'react';

const privateInfo = (props) => {
    return (
        <svg 
            height={props.height ? props.height : "18px"}
            width={props.width ? props.width : "18px"}
            viewBox="0 -31 512 512" >
            <path d="m250.625 49.277344-26.710938-34.78125c-3.390624-4.410156-8.636718-6.996094-14.199218-6.996094h-161.792969c-9.890625 0-17.90625 8.015625-17.90625 17.902344v270.757812h451.96875v-221.980468c0-9.886719-8.015625-17.902344-17.90625-17.902344h-199.253906c-5.5625 0-10.808594-2.585938-14.199219-7zm0 0" fill="#f79f48" />
            <path d="m264.824219 56.277344c-5.5625 0-10.8125-2.585938-14.199219-7l-26.714844-34.78125c-3.386718-4.410156-8.632812-6.996094-14.195312-6.996094h-41.285156l34.488281 44.902344c1.875 2.441406 4.777343 3.875 7.855469 3.875zm0 0" fill="#ef8229" />
            <path d="m464.078125 56.277344h-32.203125v239.882812h50.109375v-221.980468c0-9.886719-8.015625-17.902344-17.90625-17.902344zm0 0" fill="#ef8229" />
            <path d="m439.957031 413.4375h-367.914062c-5.125 0-9.28125-4.15625-9.28125-9.277344v-303.425781c0-5.125 4.15625-9.28125 9.28125-9.28125h367.917969c5.125 0 9.277343 4.15625 9.277343 9.28125v303.425781c0 5.121094-4.15625 9.277344-9.28125 9.277344zm0 0" fill="#ededed" />
            <path d="m490.875 443.054688h-469.75c-7.523438 0-13.625-6.101563-13.625-13.625v-262.929688c0-7.523438 6.101562-13.625 13.625-13.625h469.75c7.523438 0 13.625 6.101562 13.625 13.625v262.929688c0 7.523437-6.101562 13.625-13.625 13.625zm0 0" fill="#ffe471" />
            <path d="m490.875 152.875h-36.746094v290.179688h36.746094c7.523438 0 13.625-6.097657 13.625-13.625v-262.929688c0-7.523438-6.101562-13.625-13.625-13.625zm0 0" fill="#f2ca20" />
            <path d="m323.59375 399.722656h-135.1875c-3.6875 0-6.679688-2.992187-6.679688-6.679687v-96.683594c0-3.6875 2.992188-6.679687 6.679688-6.679687h135.1875c3.6875 0 6.679688 2.992187 6.679688 6.679687v96.683594c0 3.6875-2.992188 6.679687-6.679688 6.679687zm0 0" fill="#f79f48" />
            <path d="m323.59375 289.679688h-43.691406v110.042968h43.691406c3.6875 0 6.679688-2.992187 6.679688-6.679687v-96.683594c0-3.6875-2.992188-6.679687-6.679688-6.679687zm0 0" fill="#ef8229" />
            <path d="m449.238281 152.875v-52.140625c0-5.125-4.152343-9.28125-9.277343-9.28125h-41.09375v61.421875zm0 0" fill="#d6d6d6" />
            <path d="m256 196.796875c-33.789062 0-61.28125 27.492187-61.28125 61.28125v24.101563h-6.3125c-7.820312 0-14.179688 6.359374-14.179688 14.179687v96.6875c0 7.816406 6.359376 14.175781 14.179688 14.175781h135.1875c7.816406 0 14.179688-6.359375 14.179688-14.175781v-96.6875c0-7.816406-6.363282-14.179687-14.179688-14.179687h-6.3125v-24.101563c0-33.789063-27.492188-61.28125-61.28125-61.28125zm-46.28125 61.28125c0-25.519531 20.761719-46.28125 46.28125-46.28125s46.28125 20.761719 46.28125 46.28125v24.101563h-92.5625zm113.054688 39.101563v95.042968h-133.546876v-95.042968zm0 0" />
            <path d="m221.128906 366.960938c4.140625 0 7.5-3.355469 7.5-7.5v-23.015626c0-4.144531-3.359375-7.5-7.5-7.5-4.144531 0-7.5 3.355469-7.5 7.5v23.015626c0 4.144531 3.355469 7.5 7.5 7.5zm0 0" />
            <path d="m256 366.960938c4.140625 0 7.5-3.355469 7.5-7.5v-23.015626c0-4.144531-3.359375-7.5-7.5-7.5s-7.5 3.355469-7.5 7.5v23.015626c0 4.144531 3.359375 7.5 7.5 7.5zm0 0" />
            <path d="m290.871094 366.960938c4.144531 0 7.5-3.355469 7.5-7.5v-23.015626c0-4.144531-3.355469-7.5-7.5-7.5-4.140625 0-7.5 3.355469-7.5 7.5v23.015626c0 4.144531 3.359375 7.5 7.5 7.5zm0 0" />
            <path d="m504.5 376.589844c4.140625 0 7.5-3.359375 7.5-7.5v-202.589844c0-11.648438-9.476562-21.125-21.125-21.125h-1.390625v-71.195312c0-14.007813-11.398437-25.402344-25.40625-25.402344h-199.253906c-3.210938 0-6.296875-1.519532-8.25-4.066406l-26.714844-34.777344c-4.773437-6.21875-12.304687-9.933594-20.144531-9.933594h-161.792969c-14.007813 0-25.40625 11.394531-25.40625 25.402344v40.929687c0 4.140625 3.359375 7.5 7.5 7.5 4.144531 0 7.5-3.359375 7.5-7.5v-40.929687c0-5.738282 4.667969-10.402344 10.40625-10.402344h161.792969c3.210937 0 6.292968 1.519531 8.25 4.066406l26.710937 34.777344c4.773438 6.21875 12.308594 9.933594 20.148438 9.933594h199.253906c5.738281 0 10.40625 4.664062 10.40625 10.402344v71.195312h-17.746094v-44.644531c0-9.25-7.527343-16.777344-16.777343-16.777344h-367.917969c-9.253907 0-16.78125 7.527344-16.78125 16.777344v44.644531h-17.746094v-43.984375c0-4.144531-3.355469-7.5-7.5-7.5-4.140625 0-7.5 3.355469-7.5 7.5v43.984375h-1.390625c-11.648438 0-21.125 9.476562-21.125 21.125v262.929688c0 11.648437 9.476562 21.125 21.125 21.125h469.75c11.648438 0 21.125-9.476563 21.125-21.125v-25.417969c0-4.144531-3.359375-7.5-7.5-7.5-4.144531 0-7.5 3.355469-7.5 7.5v25.417969c0 3.378906-2.75 6.125-6.125 6.125h-469.75c-3.378906 0-6.125-2.746094-6.125-6.125v-262.929688c0-3.378906 2.746094-6.125 6.125-6.125h469.75c3.375 0 6.125 2.746094 6.125 6.125v202.589844c0 4.140625 3.359375 7.5 7.5 7.5zm-62.761719-231.214844h-371.476562v-44.644531c0-.980469.796875-1.777344 1.777343-1.777344h367.917969c.980469 0 1.78125.796875 1.78125 1.777344zm0 0" />
        </svg>
    );
}

export default privateInfo;