import React from 'react';

export const Two = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			width="32px" 
			height="32px"
			{...props}
		>
			<path
				d="M409.6-328.089c0-25.134-20.378-45.511-45.511-45.511H45.511C20.378-373.6,0-353.222,0-328.089 V-9.511C0,15.622,20.378,36,45.511,36h318.578C389.222,36,409.6,15.622,409.6-9.511V-328.089z"
				fill="#009688"
				transform="matrix(1.25 0 0 -1.25 0 45)"
			/>
			<path
				d="M262.713-247.42c15.531,0,27.534-7.054,27.534-23.279c0-16.236-12.004-23.29-25.407-23.29H147.012 c-15.531,0-27.511,7.054-27.511,23.29c0,7.396,4.574,13.756,8.101,17.988c29.286,34.918,61.042,66.674,87.848,105.483 c6.349,9.17,12.356,20.105,12.356,32.802c0,14.461-10.934,27.17-25.407,27.17c-40.573,0-21.174-57.151-55.034-57.151 c-16.93,0-25.759,11.992-25.759,25.748c0,44.453,39.515,80.077,82.91,80.077s78.313-28.57,78.313-73.375 c0-49.027-54.67-97.724-84.662-135.464H262.713z"
				fill="#fff"
				transform="matrix(1.25 0 0 -1.25 0 45)"
			/>
		</svg>
	);
};

