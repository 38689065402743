import React from 'react';

const facebookNoBg = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px" 
            width="18px"
            height="18px"
            viewBox="0 0 96 96">
            <g>
                <path d="M73.099,15.973l-9.058,0.004c-7.102,0-8.477,3.375-8.477,8.328v10.921h16.938l-0.006,17.106H55.564v43.895H37.897V52.332   h-14.77V35.226h14.77V22.612C37.897,7.972,46.84,0,59.9,0L73.1,0.021L73.099,15.973L73.099,15.973z" fill="#FFFFFF" />
            </g>
        </svg>
    )
}

export default facebookNoBg;