import React from 'react';

export const Three = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			width="32px" 
			height="32px"
			{...props}
		>
			<path
				d="M409.6-328.089c0-25.134-20.378-45.511-45.511-45.511H45.511C20.378-373.6,0-353.222,0-328.089 V-9.511C0,15.622,20.378,36,45.511,36h318.578C389.222,36,409.6,15.622,409.6-9.511V-328.089z"
				fill="#673AB7"
				transform="matrix(1.25 0 0 -1.25 0 45)"
			/>
			<path
				d="M274.318-103.105c0-22.232-9.512-41.631-28.922-53.976c25.395-11.651,43.031-35.283,43.031-63.147 c0-42.337-38.798-77.961-88.895-77.961c-52.213,0-84.662,38.445-84.662,66.321c0,13.756,14.461,23.632,27.159,23.632 c23.996,0,18.352-41.279,58.209-41.279c18.352,0,33.166,14.108,33.166,32.814c0,49.391-59.972,13.05-59.972,54.682 c0,37.035,50.085,11.992,50.085,51.143c0,13.414-9.512,23.643-25.395,23.643c-33.508,0-28.922-34.566-52.918-34.566 c-14.461,0-22.926,13.05-22.926,26.101c0,27.511,37.751,57.151,76.902,57.151C249.981-38.547,274.318-75.593,274.318-103.105"
				fill="#fff"
				transform="matrix(1.25 0 0 -1.25 0 45)"
			/>
		</svg>
	);
};

